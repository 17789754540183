<template>
  <common-card>
    <v-toolbar color="primary" dense dark flat>
      <v-toolbar-title>
        <span class="font-montserrat font-weight-bold">Cartera de Agentes</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
          <v-row class="mb-15" style="padding-left: 30px">
            <v-col md="3">
              <!-- <v-select
                  label="Canal"
                  outlined
                  style="max-width: 420px"
                  class="mt-5"
                  :items="canales"
                  item-text="name"
                  item-value="id"
                  v-model="canal"
                  v-show="canShow"
                ></v-select>
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker> -->
              <v-btn
                rounded
                color="primary"
                style="width: 100%"
                class="mt-10"
                dark
                @click="consultar"
                >Consultar</v-btn
              >
              <v-btn
                rounded
                color="primary"
                style="width:100%;margin-top:20px;"
                :disabled="bloquear"
                v-show="showDescargarInformacion()"
                ><download-csv
                  :data="reportDownload"
                  name="Cartera_Agentes.csv"
                >
                  Descargar Información
                </download-csv></v-btn
              >
            </v-col>
            <v-col cols="9" md="9">
              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-data-table
                :headers="columns"
                :items="agentes"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    outlined
                  ></v-text-field>
                </template>

                <template v-slot:item.uuid="{ item }">
                  <v-btn
                    @click="
                      $router.push('/administrador/editar-emision/' + item.uuid)
                    "
                  >
                    Ver
                  </v-btn>
                </template>

                <template v-slot:item.urlPoliza="{ item }">
                  <v-btn
                    :href="
                      'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza
                    "
                    >Descargar</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { mainAxios } from "../../../../mainAxios";

var moment = require("moment-timezone");

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles"))[0],
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      agentes: [],
      search: "",
      reportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "Nombre", value: "nombre" },
        { text: "Apellido Paterno", value: "apellidoPaterno" },
        { text: "Apellido Materno", value: "apellidoMaterno" },
        { text: "Correo", value: "correo" },
        { text: "Celular", value: "celular" },
        { text: "Telefono", value: "telefono" },
        { text: "Tipo de Agente", value: "tipoAgente" },
        { text: "Nivel Agente", value: "nivelAgente" },
        { text: "Genero", value: "genero" },
        { text: "Estatus", value: "status" },
        { text: "RFC", value: "rfc" },
        { text: "Régimen", value: "regimen" },
        { text: "Fecha de Registro", value: "fechaRegistro" },
      ],
    };
  },
  mounted() {
    console.log(localStorage);
  },

  methods: {
    showDescargarInformacion() {
      if (
        this.roles.rol == "ADMIN" ||
        this.roles.rol == "ADMINAGENT" ||
        this.roles.rol == "MANAGER" ||
        this.roles.rol == "DIRECTOR" ||
        this.roles.rol == "FINANZAS"
      ) {
        return true;
      } else {
        return false;
      }
    },
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/cartera/${this.agenteUuid}`, config)
        .then((value) => {
          this.reportDownload = [];
          this.agentes = [];
          console.log(value);
          value.data.map((element) => {
            this.agentes.push({
              nombre: this.formatNames(element.nombre),
              apellidoPaterno: this.formatNames(element.apellidoPaterno),
              apellidoMaterno: this.formatNames(element.apellidoMaterno),
              correo: element.correo,
              celular: element.celular,
              telefono: element.telefono,
              tipoAgente: element.tipoAgente,
              nivelAgente: element.nivelAgente,
              genero: element.sexo == 1 ? "Masculino" : "Femenino",
              status:
                element.status == 0
                  ? "Nuevo"
                  : element.status == 1
                  ? "Activo"
                  : element.status == 2
                  ? "Inactivo"
                  : "Baja",
              rfc: element.rfc,
              regimen: element.regimen,
              fechaRegistro: moment(
                element.fechaRegistro,
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD/MM/YYYY"),
            });

            this.reportDownload.push({
              Nombre: this.formatNames(element.nombre),
              "Apellido Paterno": this.formatNames(element.apellidoPaterno),
              "Apellido Materno": this.formatNames(element.apellidoMaterno),
              Correo: element.correo,
              Celular: element.telefono,
              "Fecha de Nacimiento": moment(
                element.fechaNacimiento,
                "YYYY-MM-DD"
              ).format("DD/MM/YYYY"),
              "Fecha de Registro": moment(
                element.fechaRegistro,
                "YYYY-MM-DD"
              ).format("DD/MM/YYYY"),
              Género: element.sexo == 1 ? "Masculino" : "Femenino",
              Estatus:
                element.status == 0
                  ? "Nuevo"
                  : element.status == 1
                  ? "Activo"
                  : element.status == 2
                  ? "Inactivo"
                  : "Baja",
              RFC: element.rfc,
              Régimen: element.regimen,
              "Estado de la República":
                element.nacimientoEstadoId == 1
                  ? "Aguascalientes"
                  : element.nacimientoEstadoId == 2
                  ? "Baja California"
                  : element.nacimientoEstadoId == 3
                  ? "Baja California Sur"
                  : element.nacimientoEstadoId == 4
                  ? "Campeche"
                  : element.nacimientoEstadoId == 5
                  ? "Coahuila de Zaragoza"
                  : element.nacimientoEstadoId == 6
                  ? "Colima"
                  : element.nacimientoEstadoId == 7
                  ? "Chiapas"
                  : element.nacimientoEstadoId == 8
                  ? "Chihuahua"
                  : element.nacimientoEstadoId == 9
                  ? "Ciudad de México"
                  : element.nacimientoEstadoId == 10
                  ? "Durango"
                  : element.nacimientoEstadoId == 11
                  ? "Guanajuato"
                  : element.nacimientoEstadoId == 12
                  ? "Guerrero"
                  : element.nacimientoEstadoId == 13
                  ? "Hidalgo"
                  : element.nacimientoEstadoId == 14
                  ? "Jalisco"
                  : element.nacimientoEstadoId == 15
                  ? "Estado de México"
                  : element.nacimientoEstadoId == 16
                  ? "Michoacán de Ocampo"
                  : element.nacimientoEstadoId == 17
                  ? "Morelos"
                  : element.nacimientoEstadoId == 18
                  ? "Nayarit"
                  : element.nacimientoEstadoId == 19
                  ? "Nuevo León"
                  : element.nacimientoEstadoId == 20
                  ? "Oaxaca"
                  : element.nacimientoEstadoId == 21
                  ? "Puebla"
                  : element.nacimientoEstadoId == 22
                  ? "Querétaro"
                  : element.nacimientoEstadoId == 23
                  ? "Quintana Roo"
                  : element.nacimientoEstadoId == 24
                  ? "San Luis Potosí"
                  : element.nacimientoEstadoId == 25
                  ? "Sinaloa"
                  : element.nacimientoEstadoId == 26
                  ? "Sonora"
                  : element.nacimientoEstadoId == 27
                  ? "Tabasco"
                  : element.nacimientoEstadoId == 28
                  ? "Tamaulipas"
                  : element.nacimientoEstadoId == 29
                  ? "Tlaxcala"
                  : element.nacimientoEstadoId == 30
                  ? "Veracruz"
                  : element.nacimientoEstadoId == 31
                  ? "Yucatán"
                  : element.nacimientoEstadoId == 32
                  ? "Zacatecas"
                  : element.nacimientoEstadoId == 33
                  ? "Desconocio"
                  : "",
              "Estado Civil": element.statusCivil
                ? element.statusCivil
                : "No Disponible", //<----Falta
              "Motivo de Baja": element.motivoBaja
                ? element.motivoBaja
                : "No Disponible", //<----Falta
              "Tipo de Agente": element.tipoAgente,
              "Razón Social": element.razonSocial,
              "Clabe Bancaria": element.clabeBancaria,
              "Clave Agente": element.claveAgente,
              "Tipo Cedula": element.tipoCedula
                ? element.tipoCedula
                : "No Disponible", //<----Falta
              "Nivel Agente": element.nivelAgente,
              "Número de Cartera": element.numCartera
                ? element.numCartera
                : "No Disponible", //<----Falta
              Cédula: element.cedula ? element.cedula : "No Disponible", //<----Falta
              RC: element.rc ? element.rc : "No Disponible", //<----Falta
              "Vigencia Póliza RC": element.vigenciaPolizaRc
                ? element.vigenciaPolizaRc
                : "No Disponible", //<----Falta
              "Vigencia Cédula": element.vigenciaCedula
                ? element.vigenciaCedula
                : "No Disponible", //<----Falta
            });
            this.bloquear = false;
          });
        });
    },
    newEmision() {
      this.$router.push("/administrador/carga-emisiones");
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    formatNames(str) {
      return str
      .split(' ')
      .map(element => element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()) 
      .join(' ')
    }
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
  },
};
</script>
