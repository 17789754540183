<template>
  <v-expansion-panel-content>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Periodicidad"
              v-model="formData.pago_id"
              :items="pagoList"
              item-text="name"
              item-value="id"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
              :readonly="!canEditThird"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-autocomplete
              label="Forma de Pago"
              v-model="formData.forma_pago"
              :items="formaPagoList"
              item-text="name"
              item-value="id"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
              :readonly="!canEditThird"
            ></v-autocomplete>
          </v-col>

          <v-col m="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-text-field
              label="Años"
              v-model="formData.anios"
              type="number"
              :min="1"
              outlined
              :rules="requiredRule"
              color="#00a7e4"
              :readonly="!canEditThird"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-text-field
              label="Número de cuotas"
              v-model="formData.numeroCuotas"
              tipo_poliza="number"
              readonly
              outlined
              :rules="requiredRule"
              :min="1"
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="3"
            xl="3"
            v-if="
              (formData.status == 1 ||
                formData.status == 2 ||
                formData.status == 3 ||
                formData.status == 4) &&
                canEdit
            "
          >
            <v-checkbox
              label="Generar Cuotas nuevamente"
              v-model="formData.checkGenerarCuotas"
              :disabled="formData.status < 1"
            ></v-checkbox>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-text-field
              label="Prima neta"
              v-model="formData.prima_neta"
              :items="formaPagoList"
              tipo_poliza="number"
              outlined
              :min="1"
              :rules="requiredRule"
              color="#00a7e4"
              :readonly="!canEditThird"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-text-field
              label="Prima total"
              v-model="formData.prima_total"
              :items="formaPagoList"
              tipo_poliza="number"
              outlined
              :min="1"
              :rules="requiredRule"
              color="#00a7e4"
              :readonly="!canEditThird"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3" v-if="formData.pago_id != null">
            <v-text-field
              label="Monto de Primer Pago"
              v-model="formData.primer_pago"
              tipo_poliza="number"
              outlined
              :min="1"
              color="#00a7e4"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="3"
            xl="3"
            v-if="
              (formData.pago_id != null && formData.pago_id != 1) ||
                (formData.pago_id != null &&
                  formData.pago_id == 1 &&
                  formData.anios > 1)
            "
          >
            <v-text-field
              label="Monto de Pago Subsecuente"
              v-model="formData.subsecuente_pago"
              tipo_poliza="number"
              outlined
              :min="1"
              color="#00a7e4"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-if="formData.pago_id != null"
              v-model="formData.fechaPrimerPago"
              type="date"
              label="Fecha primer pago"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="formatData">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </v-expansion-panel-content>
</template>

<script>
// import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      infoCards: {},
      run: false,
      formData: {
        id: null,
        pago_id: null,
        forma_pago: null,
        anios: null,
        numeroCuotas: null,
        categoriaSelected: null,
        prima_neta: null,
        prima_total: null,
        primer_pago: null,
        subsecuente_pago: null,
        fechaPrimerPago: null,
        status: null,
        agente: null,
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      pagoList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      formaPagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  watch: {
    "formData.pago_id"() {
      this.calculateCuotas();
    },
    "formData.anios"() {
      this.calculateCuotas();
    },
  },
  mounted() {
    this.fillData();
  },
  computed: {
    canEdit() {
      let canView = false;
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "OPERACIONES",
        "AGENTCC",
        "MANAGERCC",
        "COBRANZACC",
        "RENOVACIONESCC",
        "OPERADOR",
      ];
      rolesToView.forEach((element) => {
        if (this.rol == element) {
          canView = true;
        }
      });
      return canView;
    },
    canEditThird() {
      let canView = false;
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "OPERACIONES",
        "FINANZAS",
        "OPERADOR",
        "AGENTCC",
        "OPERADORCC",
        "AGENTCCSPONSORS",
        "MANAGERCC",
      ];
      rolesToView.forEach((element) => {
        if (this.rol == element) {
          canView = true;
        }
      });
      return canView;
    },
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getFormaDePagoName(val) {
      switch (val) {
        case 1:
          return "Tarjeta de Crédito";
        case 2:
          return "Tarjeta de Débito";
        case 3:
          return "Depósito en Banco";
        case 4:
          return "Transferencia";
        case 5:
          return "Cheque";
        case 6:
          return "Efectivo";
        case 7:
          return "Domiciliación";
        case 8:
          return "Cargo Único";
        default:
          return "Sin llenar";
      }
    },
    calculateCuotas() {
      switch (this.formData.pago_id) {
        case 1:
          this.formData.numeroCuotas = 1 * this.formData.anios;
          break;
        case 2:
          this.formData.numeroCuotas = 2 * this.formData.anios;
          break;
        case 3:
          this.formData.numeroCuotas = 4 * this.formData.anios;
          break;
        case 4:
          this.formData.numeroCuotas = 12 * this.formData.anios;
          break;
        default:
          break;
      }
    },
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatoMonedaMXN.format(val);
    },
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.polizaInfo[e]) {
          this.formData[e] = this.polizaInfo[e];
        }
      });
      this.formData.status = this.polizaInfo.status;
    },
    formatData() {
      this.formData.anios = parseInt(this.formData.anios);
      this.run = true;
    },
  },
};
</script>
