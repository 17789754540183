<template>
  <common-card>
    <v-toolbar color="primary" dense dark flat>
      <v-toolbar-title>
        <span class="font-montserrat font-weight-bold">Pólizas Emitidas</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
          <v-row class="mb-15" style="padding-left: 30px">
            <v-col md="3">
              <v-select
                label="Canal"
                outlined
                style="max-width: 420px"
                class="mt-5"
                :items="canales"
                item-text="name"
                item-value="id"
                v-model="canal"
                v-show="canShow"
              ></v-select>
              <h3>Selecciona el rango de fechas</h3>
              <v-date-picker
                style="width: 100%"
                v-model="dates"
                range
              ></v-date-picker>
              <v-btn
                rounded
                color="primary"
                style="width: 100%"
                dark
                @click="consultar"
                >Consultar</v-btn
              >
              <v-btn
                rounded
                color="primary"
                style="width:100%;margin-top:20px;"
                :disabled="bloquear"
                ><download-csv
                  :data="reportDownload"
                  name="Reporte_Polizas_Emitidas.csv"
                >
                  Descargar Información
                </download-csv></v-btn
              >
            </v-col>
            <v-col cols="9" md="9">
              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-data-table
                :headers="columns"
                :items="polizas"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    outlined
                  ></v-text-field>
                </template>

                <template v-slot:item.uuid="{ item }">
                  <v-btn
                    @click="
                      $router.push('/administrador/editar-emision/' + item.uuid)
                    "
                  >
                    Ver
                  </v-btn>
                </template>

                <template v-slot:item.urlPoliza="{ item }">
                  <v-btn
                    :href="
                      'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza
                    "
                    >Descargar</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { mainAxios } from "../../../../mainAxios";

var moment = require("moment-timezone");

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      polizas: [],
      search: "",
      reportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "No. Póliza", value: "idPoliza" },
        { text: "Estatus de la Póliza", value: "status" },
        { text: "Cliente", value: "cliente" },
        { text: "Agente", value: "agente" },
        { text: "Nombre de Producto", value: "nombreProducto" },
        { text: "Ramo", value: "ramo" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Nueva / Renovacion", value: "nuevaRenovacion" },
        { text: "Inicio de Vigencia", value: "inicioVigencia" },
        { text: "Fin de Vigencia", value: "finVigencia" },
        { text: "Periodicidad de Pago", value: "periodicidadPago" },

        { text: "Prima Total", value: "primaTotal" },
        { text: "Primer Pago", value: "primerPago" },
        { text: "Subsecuente", value: "subsecuente" },
        { text: "Prima Neta", value: "primaNeta" },

        { text: "Ejecutivo", value: "ejecutivo" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      mainAxios
        .post(
          `/v1/emision/${this.canal ? this.canal : 0}/1/${this.agenteUuid}`,
          fechas,
          config
        )
        .then((value) => {
          this.reportDownload = [];
          this.polizas = [];
          console.log(value);
          value.data.map((element) => {
            this.polizas.push({
              idPoliza: element.poliza,
              status: element.status,
              // cliente: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
              cliente: element.nombreCliente
                ? this.formatNames(element.nombreCliente)
                : `${element.nombre}` +
                  " " +
                  `${element.apellido_Paterno}` +
                  " " +
                  `${element.apellido_Materno}`,
              agente: this.formatNames(element.nombreAgente),
              nombreProducto: element.nombreProducto,
              ramo: element.nombreRamo,
              aseguradora: element.nombreAseguradora,
              nuevaRenovacion: element.nuevaRenovacion,
              inicioVigencia: element.fechaInicioVigencia
                ? moment
                    .utc(element.fechaInicioVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              finVigencia: element.fechaFinVigencia
                ? moment
                    .utc(element.fechaFinVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              periodicidadPago: element.periodicidad,

              primaTotal: "$" + element.primaTotal,
              primerPago: "$" + element.primerPago,
              subsecuente:
                element.subsecuente == null
                  ? "$0.00"
                  : "$" + element.subsecuente,
              primaNeta: "$" + element.primaNeta,

              ejecutivo: element.nombreEjecutivo,
            });

            this.reportDownload.push({
              "No. Póliza": element.poliza,
              "Póliza ID Interna": element.id ? element.id : "No Disponible",
              "Estatus de la Póliza": element.status,
              Cliente: element.nombreCliente
                ? this.formatNames(element.nombreCliente)
                : `${element.nombre}` +
                  " " +
                  `${element.apellido_Paterno}` +
                  " " +
                  `${element.apellido_Materno}`,
              Agente: this.formatNames(element.nombreAgente),
              "Estatus agente": element.statusAgente
                ? element.statusAgente == 0
                  ? "Nuevo"
                  : element.statusAgente == 1
                  ? "Activo"
                  : element.statusAgente == 2
                  ? "inactivo"
                  : element.statusAgente == "-1"
                  ? "Baja"
                  : "No disponible"
                : "No disponible",

              "Canal Agente": element.canal ? element.canal : "No Disponible",
              "Nombre de Producto": element.nombreProducto,
              Ramo: element.nombreRamo,
              Aseguradora: element.nombreAseguradora,
              "Nueva / Renovación": element.nuevaRenovacion,
              "Inicio de Vigencia": element.fechaInicioVigencia
                ? moment
                    .utc(element.fechaInicioVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fin de Vigencia": element.fechaFinVigencia
                ? moment
                    .utc(element.fechaFinVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Periodicidad de Pago": element.periodicidad,
              "Número de Cuotas": element.numCuotas,

              "Prima Total": "$" + element.primaTotal,
              "Primer Pago": "$" + element.primerPago,
              Subsecuente:
                element.subsecuente == null
                  ? "$0.00"
                  : "$" + element.subsecuente,
              "Prima neta": "$" + element.primaNeta,

              Años: element.anios,
              "Forma de Pago":
                element.formaPago == 1
                  ? "Tarjeta de Crédito"
                  : element.formaPago == 2
                  ? "Tarjeta de Débito"
                  : element.formaPago == 3
                  ? "Depósito en banco"
                  : element.formaPago == 4
                  ? "Transferencia"
                  : element.formaPago == 5
                  ? "Cheque"
                  : element.formaPago == 6
                  ? "Efectivo"
                  : element.formaPago == 7
                  ? "Domiciliación"
                  : "Cargo único",
              "Fecha Creación": element.fechaCreacion
                ? moment
                    .utc(element.fechaCreacion)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fecha Emisión": element.fechaEmision
                ? moment
                    .utc(element.fechaEmision)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fecha primer pago": element.fechaPrimerPago
                ? moment
                    .utc(element.fechaPrimerPago)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              Ejecutivo: element.nombreEjecutivo,
              "Gerente Comercial": element.nombreComercialGeneral
                ? element.nombreComercialGeneral
                : "No Disponible",
              "Fecha de Cancelación": element.fechaCancelacion
                ? moment(element.fechaCancelacion, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )
                : "No Disponible",
              "Motivo de Cancelación / Finalización": element.motivoCancelacion
                ? element.motivoCancelacion
                : "No Disponible",
              "Renueva a": element.emisionRenovadaPor
                ? element.emisionRenovadaPor
                : "No Disponible",
            });
            this.bloquear = false;
          });
        });
    },
    newEmision() {
      this.$router.push("/administrador/carga-emisiones");
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    formatNames(str) {
      return str
      .split(' ')
      .map(element => element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()) 
      .join(' ')
    }
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
  },
};
</script>
